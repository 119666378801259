import { getToken, returnError } from './helperFunc';
import {
	registerOperatorAction,
	verifyOperatorAction,
	loginOperatorAction,
	createRMAction,
	createRazerAction,
	checkSessionAction,
	loyaltyAdminCheckAction,
	fetchRMAction,
	fetchTokenkeyAction,
	tokenkeyAction,
	loginDistributorAction,
	fetchOperatorsAction,
	loginStaffAction,
	resetPasswordAction,
	sendResetEmailAction,
	createDuitnowAction,
	fetchDuitnowAction,
	fetchTokenkeyDuitnowAction,
	tokenkeyDuitnowAction,
	fetchTokenkeyRazerAction,
	tokenkeyRazerAction,
	fetchRazerAction,
	updateRMAction,
	updateRazerAction,
	updateDuitnowAction,
	verifyDistributorAction,
	verifyOtpOperatorAction,
	verifyStaffAction,
	fetch2faAction,
	verifyUser2faAction,
	createGhlAction,
	fetchTokenkeyGhlAction,
	tokenkeyGhlAction,
	fetchGhlAction,
	updateGhlAction,
} from './requestEndpoints';

const SET_USER_LOADING = 'SET_USER_LOADING';
const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR';

const STAFF_LOGIN_SUCCESS = 'STAFF_LOGIN_SUCCESS';
const STAFF_LOGIN_FAIL = 'STAFF_LOGIN_FAIL';

const OPERATOR_LOGIN_SUCCESS = 'OPERATOR_LOGIN_SUCCESS';
const OPERATOR_LOGIN_FAIL = 'OPERATOR_LOGIN_FAIL';

const DISTRIBUTOR_LOGIN_SUCCESS = 'DISTRIBUTOR_LOGIN_SUCCESS';
const DISTRIBUTOR_LOGIN_FAIL = 'DISTRIBUTOR_LOGIN_FAIL';

const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';

const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS';
const USER_VERIFY_FAIL = 'USER_VERIFY_FAIL';

const CREATE_RM_SUCCESS = 'CREATE_RM_SUCCESS';
const CREATE_RM_FAIL = 'CREATE_RM_FAIL';

const CREATE_RAZER_SUCCESS = 'CREATE_RAZER_SUCCESS';
const CREATE_RAZER_FAIL = 'CREATE_RAZER_FAIL';

const FETCH_RM_SUCCESS = 'FETCH_RM_SUCCESS';
const FETCH_RM_FAIL = 'FETCH_RM_FAIL';

const FETCH_RAZER_SUCCESS = 'FETCH_RAZER_SUCCESS';
const FETCH_RAZER_FAIL = 'FETCH_RAZER_FAIL';

const FETCH_TOKENKEY_SUCCESS = 'FETCH_TOKENKEY_SUCCESS';
const FETCH_TOKENKEY_FAIL = 'FETCH_TOKENKEY_FAIL';

const UPDATE_TOKENKEY_SUCCESS = 'UPDATE_TOKENKEY_SUCCESS';
const UPDATE_TOKENKEY_FAIL = 'UPDATE_TOKENKEY_FAIL';

const CREATE_DUITNOW_SUCCESS = 'CREATE_DUITNOW_SUCCESS';
const CREATE_DUITNOW_FAIL = 'CREATE_DUITNOW_FAIL';

const FETCH_DUITNOW_SUCCESS = 'FETCH_DUITNOW_SUCCESS';
const FETCH_DUITNOW_FAIL = 'FETCH_DUITNOW_FAIL';

const FETCH_TOKENKEY_DUITNOW_SUCCESS = 'FETCH_TOKENKEY_DUITNOW_SUCCESS';
const FETCH_TOKENKEY_DUITNOW_FAIL = 'FETCH_TOKENKEY_DUITNOW_FAIL';

const UPDATE_TOKENKEY_DUITNOW_SUCCESS = 'UPDATE_TOKENKEY_DUITNOW_SUCCESS';
const UPDATE_TOKENKEY_DUITNOW_FAIL = 'UPDATE_TOKENKEY_DUITNOW_FAIL';

const FETCH_TOKENKEY_RAZER_SUCCESS = 'FETCH_TOKENKEY_RAZER_SUCCESS';
const FETCH_TOKENKEY_RAZER_FAIL = 'FETCH_TOKENKEY_RAZER_FAIL';

const UPDATE_TOKENKEY_RAZER_SUCCESS = 'UPDATE_TOKENKEY_RAZER_SUCCESS';
const UPDATE_TOKENKEY_RAZER_FAIL = 'UPDATE_TOKENKEY_RAZER_FAIL';

const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

const LOYALTY_ADMIN_CHECK_SUCCESS = 'LOYALTY_ADMIN_CHECK_SUCCESS';
const LOYALTY_ADMIN_CHECK_FAIL = 'LOYALTY_ADMIN_CHECK_FAIL';

const SUMMARY_FILTER_SUCCESS = 'SUMMARY_FILTER_SUCCESS';
const SUMMARY_FILTER_FAIL = 'SUMMARY_FILTER_FAIL';

const SALES_PERFORMANCE_FILTER_SUCCESS = 'SALES_PERFORMANCE_FILTER_SUCCESS';
const SALES_PERFORMANCE_FILTER_FAIL = 'SALES_PERFORMANCE_FILTER_FAIL';

const MACHINE_UTIL_FILTER_SUCCESS = 'MACHINE_UTIL_FILTER_SUCCESS';
const MACHINE_UTIL_FILTER_FAIL = 'MACHINE_UTIL_FILTER_FAIL';

const CLEAR_FILTER_SUCCESS = 'CLEAR_FILTER_SUCCESS';

const FETCH_OPERATORS_SUCCESS = 'FETCH_OPERATORS_SUCCESS';
const FETCH_OPERATORS_FAIL = 'FETCH_OPERATORS_FAIL';

const FETCH_CONFIGS_FAIL = 'FETCH_CONFIGS_FAIL';

const UPDATE_RM_SUCCESS = 'UPDATE_RM_SUCCESS';
const UPDATE_RM_FAIL = 'UPDATE_RM_FAIL';

const UPDATE_RAZER_SUCCESS = 'UPDATE_RAZER_SUCCESS';
const UPDATE_RAZER_FAIL = 'UPDATE_RAZER_FAIL';

const UPDATE_DUITNOW_SUCCESS = 'UPDATE_DUITNOW_SUCCESS';
const UPDATE_DUITNOW_FAIL = 'UPDATE_DUITNOW_FAIL';

const DISTRIBUTOR_VERIFY_SUCCESS = 'DISTRIBUTOR_VERIFY_SUCCESS';
const DISTRIBUTOR_VERIFY_FAIL = 'DISTRIBUTOR_VERIFY_FAIL';

const OPERATOR_VERIFY_SUCCESS = 'OPERATOR_VERIFY_SUCCESS';
const OPERATOR_VERIFY_FAIL = 'OPERATOR_VERIFY_FAIL';

const STAFF_VERIFY_SUCCESS = 'STAFF_VERIFY_SUCCESS';
const STAFF_VERIFY_FAIL = 'STAFF_VERIFY_FAIL';

const FETCH_2FA_SUCCESS = 'FETCH_2FA_SUCCESS';
const FETCH_2FA_FAIL = 'FETCH_2FA_FAIL';

const USER_2FA_VERIFY_SUCCESS = 'USER_2FA_VERIFY_SUCCESS';
const USER_2FA_VERIFY_FAIL = 'USER_2FA_VERIFY_FAIL';

const CREATE_GHL_SUCCESS = 'CREATE_GHL_SUCCESS';
const CREATE_GHL_FAIL = 'CREATE_GHL_FAIL';

const FETCH_GHL_SUCCESS = 'FETCH_GHL_SUCCESS';
const FETCH_GHL_FAIL = 'FETCH_GHL_FAIL';

const UPDATE_GHL_SUCCESS = 'UPDATE_GHL_SUCCESS';
const UPDATE_GHL_FAIL = 'UPDATE_GHL_FAIL';

const FETCH_TOKENKEY_GHL_SUCCESS = 'FETCH_TOKENKEY_GHL_SUCCESS';
const FETCH_TOKENKEY_GHL_FAIL = 'FETCH_TOKENKEY_GHL_FAIL';

const UPDATE_TOKENKEY_GHL_SUCCESS = 'UPDATE_TOKENKEY_GHL_SUCCESS';
const UPDATE_TOKENKEY_GHL_FAIL = 'UPDATE_TOKENKEY_GHL_FAIL';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const updateRM = (RmDetails) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await updateRMAction(RmDetails, token);

		dispatch({ type: UPDATE_RM_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: UPDATE_RM_FAIL, payload: returnError(error) });
	}
};

export const updateRazer = (RazerDetails) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await updateRazerAction(RazerDetails, token);

		dispatch({ type: UPDATE_RAZER_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: UPDATE_RAZER_FAIL, payload: returnError(error) });
	}
};

export const updateDuitnow = (DuitnowDetails) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await updateDuitnowAction(DuitnowDetails, token);

		dispatch({ type: UPDATE_DUITNOW_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: UPDATE_DUITNOW_FAIL, payload: returnError(error) });
	}
};

export const updateGhl = (GhlDetails) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await updateGhlAction(GhlDetails, token);

		dispatch({ type: UPDATE_GHL_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: UPDATE_GHL_FAIL, payload: returnError(error) });
	}
};


export const registerOperator = (newUser) => async (dispatch) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { data } = registerOperatorAction(newUser);

		dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: USER_REGISTER_FAIL, payload: returnError(error) });
	}
};

export const verifyOperator = (token, callback) => async (dispatch) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { data } = verifyOperatorAction(token);
		// callback();
		dispatch({ type: USER_2FA_VERIFY_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: USER_2FA_VERIFY_FAIL, payload: returnError(error) });
	}
};

export const loginStaff = (loginUser) => async (dispatch) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { data } = await loginStaffAction(loginUser);

		// console.log(loginUser, data);

		dispatch({ type: STAFF_LOGIN_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: STAFF_LOGIN_FAIL, payload: returnError(error) });
	}
};

export const loginOperator = (loginUser) => async (dispatch) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { data } = await loginOperatorAction(loginUser);

		// console.log(loginUser, data);

		dispatch({ type: OPERATOR_LOGIN_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: OPERATOR_LOGIN_FAIL, payload: returnError(error) });
	}
};

export const loginDistributor = (loginUser) => async (dispatch) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { data } = await loginDistributorAction(loginUser);

		dispatch({ type: DISTRIBUTOR_LOGIN_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: DISTRIBUTOR_LOGIN_FAIL, payload: returnError(error) });
	}
};

export const createRM = (RmDetails) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });

		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await createRMAction(RmDetails, token);

		dispatch({ type: CREATE_RM_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: CREATE_RM_FAIL, payload: returnError(error) });
	}
};

export const createRazer = (RazerDetails) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });

		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await createRazerAction(RazerDetails, token);

		dispatch({ type: CREATE_RAZER_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: CREATE_RAZER_FAIL, payload: returnError(error) });
	}
};

export const createDuitnow = (DuitnowDetails) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });

		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await createDuitnowAction(DuitnowDetails, token);

		dispatch({ type: CREATE_DUITNOW_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: CREATE_DUITNOW_FAIL, payload: returnError(error) });
	}
};

export const createGhl = (GhlDetails) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });

		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await createGhlAction(GhlDetails, token);

		dispatch({ type: CREATE_GHL_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: CREATE_GHL_FAIL, payload: returnError(error) });
	}
};

export const checkUserSession = (token) => async (dispatch) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });

		const { data } = await checkSessionAction(token);

		dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: USER_DETAILS_FAIL, payload: returnError(error) });
	}
};

export const fetchRM = (operatorId) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await fetchRMAction(operatorId, token);
		// console.log('data', data.data);
		dispatch({ type: FETCH_RM_SUCCESS, payload: data.data });
	} catch (error) {
		dispatch({ type: FETCH_RM_FAIL, payload: returnError(error) });
	}
};

export const fetchRazer = (operatorId) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await fetchRazerAction(operatorId, token);
		// console.log('data', data.data);
		dispatch({ type: FETCH_RAZER_SUCCESS, payload: data.data });
	} catch (error) {
		dispatch({ type: FETCH_RAZER_FAIL, payload: returnError(error) });
	}
};

export const fetchGhl = (operatorId) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await fetchGhlAction(operatorId, token);
		// console.log('data', data.data);
		dispatch({ type: FETCH_GHL_SUCCESS, payload: data.data });
	} catch (error) {
		dispatch({ type: FETCH_GHL_FAIL, payload: returnError(error) });
	}
};

export const updateTokenkey =
	(tokenkeyDetails) => async (dispatch, getState) => {
		try {
			dispatch({ type: CLEAR_USER_ERROR });
			dispatch({ type: SET_USER_LOADING });

			const { user } = getState();

			const token = getToken(user);

			const { data } = await tokenkeyAction(tokenkeyDetails, token);

			dispatch({ type: UPDATE_TOKENKEY_SUCCESS, payload: data });
		} catch (error) {
			dispatch({ type: UPDATE_TOKENKEY_FAIL, payload: returnError(error) });
		}
	};

export const fetchTokenkey = (operatorId) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();

		const token = getToken(user);

		const { data } = await fetchTokenkeyAction(operatorId, token);

		dispatch({ type: FETCH_TOKENKEY_SUCCESS, payload: data.data });
	} catch (error) {
		dispatch({ type: FETCH_TOKENKEY_FAIL, payload: returnError(error) });
	}
};

export const updateTokenkeyRazer =
	(tokenkeyDetails) => async (dispatch, getState) => {
		try {
			dispatch({ type: CLEAR_USER_ERROR });
			dispatch({ type: SET_USER_LOADING });

			const { user } = getState();

			const token = getToken(user);

			const { data } = await tokenkeyRazerAction(tokenkeyDetails, token);

			dispatch({ type: UPDATE_TOKENKEY_RAZER_SUCCESS, payload: data });
		} catch (error) {
			dispatch({ type: UPDATE_TOKENKEY_RAZER_FAIL, payload: returnError(error) });
		}
	};

export const updateTokenkeyGhl =
	(tokenkeyDetails) => async (dispatch, getState) => {
		try {
			dispatch({ type: CLEAR_USER_ERROR });
			dispatch({ type: SET_USER_LOADING });

			const { user } = getState();

			const token = getToken(user);

			const { data } = await tokenkeyGhlAction(tokenkeyDetails, token);

			dispatch({ type: UPDATE_TOKENKEY_GHL_SUCCESS, payload: data });
		} catch (error) {
			dispatch({ type: UPDATE_TOKENKEY_GHL_FAIL, payload: returnError(error) });
		}
	};

export const fetchTokenkeyRazer = (operatorId) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();

		const token = getToken(user);

		const { data } = await fetchTokenkeyRazerAction(operatorId, token);

		dispatch({ type: FETCH_TOKENKEY_RAZER_SUCCESS, payload: data.data });
	} catch (error) {
		dispatch({ type: FETCH_TOKENKEY_RAZER_FAIL, payload: returnError(error) });
	}
};

export const fetchTokenkeyGhl = (operatorId) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();

		const token = getToken(user);

		const { data } = await fetchTokenkeyGhlAction(operatorId, token);

		dispatch({ type: FETCH_TOKENKEY_GHL_SUCCESS, payload: data.data });
	} catch (error) {
		dispatch({ type: FETCH_TOKENKEY_GHL_FAIL, payload: returnError(error) });
	}
};

export const fetchDuitnow = (operatorId) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await fetchDuitnowAction(operatorId, token);
		// console.log('data', data.data);
		dispatch({ type: FETCH_DUITNOW_SUCCESS, payload: data.data });
	} catch (error) {
		dispatch({ type: FETCH_DUITNOW_FAIL, payload: returnError(error) });
	}
};

export const updateTokenkeyDuitnow =
	(tokenkeyDuitnowDetails) => async (dispatch, getState) => {
		try {
			dispatch({ type: CLEAR_USER_ERROR });
			dispatch({ type: SET_USER_LOADING });

			const { user } = getState();

			const token = getToken(user);

			const { data } = await tokenkeyDuitnowAction(tokenkeyDuitnowDetails, token);

			dispatch({ type: UPDATE_TOKENKEY_DUITNOW_SUCCESS, payload: data });
		} catch (error) {
			dispatch({ type: UPDATE_TOKENKEY_DUITNOW_FAIL, payload: returnError(error) });
		}
	};

export const fetchTokenkeyDuitnow = (operatorId) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();

		const token = getToken(user);

		const { data } = await fetchTokenkeyDuitnowAction(operatorId, token);

		dispatch({ type: FETCH_TOKENKEY_DUITNOW_SUCCESS, payload: data.data });
	} catch (error) {
		dispatch({ type: FETCH_TOKENKEY_DUITNOW_FAIL, payload: returnError(error) });
	}
};

export const loyaltyAdminCheck = () => async (dispatch, getState) => {
	const { user } = getState();
	const token = getToken(user);
	try {
		const { data } = await loyaltyAdminCheckAction(token);

		dispatch({ type: LOYALTY_ADMIN_CHECK_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: LOYALTY_ADMIN_CHECK_FAIL, payload: returnError(error) });
	}
};

export const fetchOperators = () => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const distributorToken = getToken(user);

		const { data } = await fetchOperatorsAction(distributorToken);

		dispatch({ type: FETCH_OPERATORS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: FETCH_OPERATORS_FAIL, payload: returnError(error) });
	}
};

export const resetPassword = (body) => async (dispatch, getState) => {
	try {
		let reqBody = body;

		if (body?.inApp) {
			const { user } = getState();
			const token = getToken(user);
			reqBody.token = token;
		}

		const { data } = await resetPasswordAction(reqBody);
		return data;
	} catch (error) {
		return returnError(error);
	}
};


export const verifyDistributor = (id, otp) => async (dispatch) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { data } = await verifyDistributorAction(id, otp);

		dispatch({ type: DISTRIBUTOR_VERIFY_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: DISTRIBUTOR_VERIFY_FAIL, payload: returnError(error) });
	}
};


export const verifyOtpOperator = (id, otp) => async (dispatch) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { data } = await verifyOtpOperatorAction(id, otp);

		dispatch({ type: OPERATOR_VERIFY_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: OPERATOR_VERIFY_FAIL, payload: returnError(error) });
	}
};


export const verifyStaff = (id, otp) => async (dispatch) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { data } = await verifyStaffAction(id, otp);

		dispatch({ type: STAFF_VERIFY_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: STAFF_VERIFY_FAIL, payload: returnError(error) });
	}
};

export const verifyUser2fa = (sendBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await verifyUser2faAction(sendBody, token);

		dispatch({ type: USER_VERIFY_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: USER_VERIFY_FAIL, payload: returnError(error) });
	}
};


export const sendResetEmail = (body) => async (dispatch) => {
	try {
		const { data } = await sendResetEmailAction(body);
		return data;
	} catch (error) {
		return returnError(error);
	}
};

export const fetch2FA = (operatorId) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_USER_ERROR });
		dispatch({ type: SET_USER_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await fetch2faAction(token);
		// console.log('data', data.data);
		dispatch({ type: FETCH_2FA_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: FETCH_2FA_FAIL, payload: returnError(error) });
	}
};

const initialState = {
	language: 'en',
	operator: null,
	error: null,
	loading: false,
	rmExist: [],
	razerExist: [],
	ghlExist: [],
	tokenKey: [],
	tokenKeyRazer: [],
	tokenKeyGhl: [],
	duitnowExist: [],
	tokenKeyDuitnow: [],
	loyaltyToken: null,
	staffToken: localStorage.getItem('laundro-v2-staff-token'),
	operatorToken: localStorage.getItem('laundro-v2-operator-token'),
	distributorToken: localStorage.getItem('laundro-v2-distributor-token'),
	operators: [],
	distributor: null,
	staff: null,
	rmConfig: [],
	razerConfig: [],
	ghlConfig: [],
	duitnowConfig: []
};

export const userReducer = function (state = initialState, action) {
	switch (action.type) {
		// this is for fetching loading time setter
		case SET_USER_LOADING:
			return {
				...state,
				loading: true,
				error: null,
			};

		case CLEAR_USER_ERROR:
			return {
				...state,
				error: null,
			};

		case CREATE_RM_SUCCESS:
			return {
				...state,
				rm: action.payload,
				loading: false,
				error: null,
			};
		
		case UPDATE_RM_SUCCESS:
			return {
				...state,
				rm: action.payload,
				loading: false,
				error: null,
			};

		case UPDATE_RM_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		
		case UPDATE_RAZER_SUCCESS:
			return {
				...state,
				razer: action.payload,
				loading: false,
				error: null,
			};

		case UPDATE_RAZER_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
			case UPDATE_GHL_SUCCESS:
				return {
					...state,
					ghl: action.payload,
					loading: false,
					error: null,
				};
	
			case UPDATE_GHL_FAIL:
				return {
					...state,
					loading: false,
					error: action.payload,
				};
		case UPDATE_DUITNOW_SUCCESS:
			return {
				...state,
				duitnow: action.payload,
				loading: false,
				error: null,
			};

		case UPDATE_DUITNOW_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};


		case CREATE_RAZER_SUCCESS:
			return {
				...state,
				razer: action.payload,
				loading: false,
				error: null,
			};

			case CREATE_GHL_SUCCESS:
				return {
					...state,
					ghl: action.payload,
					loading: false,
					error: null,
				};

		case FETCH_RM_SUCCESS:
			return {
				...state,
				rmExist: action.payload,
				loading: false,
				error: null,
			};

		case FETCH_RAZER_SUCCESS:
			return {
				...state,
				razerExist: action.payload,
				loading: false,
				error: null,
			};

			case FETCH_GHL_SUCCESS:
				return {
					...state,
					ghlExist: action.payload,
					loading: false,
					error: null,
				};

		case FETCH_CONFIGS_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		case UPDATE_TOKENKEY_SUCCESS:
			return {
				...state,
				tokenConfigKey: action.payload,
				loading: false,
				error: null,
			};

		case FETCH_TOKENKEY_SUCCESS:
			return {
				...state,
				tokenKey: action.payload,
				loading: false,
				error: null,
			};

		case CREATE_DUITNOW_SUCCESS:
			return {
				...state,
				duitnow: action.payload,
				loading: false,
				error: null,
			};

		case FETCH_DUITNOW_SUCCESS:
			return {
				...state,
				duitnowExist: action.payload,
				loading: false,
				error: null,
			};

		case UPDATE_TOKENKEY_DUITNOW_SUCCESS:
			return {
				...state,
				tokenConfigKeyDuitnow: action.payload,
				loading: false,
				error: null,
			};

		case FETCH_TOKENKEY_DUITNOW_SUCCESS:
			return {
				...state,
				tokenKeyDuitnow: action.payload,
				loading: false,
				error: null,
			};

		case UPDATE_TOKENKEY_RAZER_SUCCESS:
			return {
				...state,
				tokenConfigKeyRazer: action.payload,
				loading: false,
				error: null,
			};

		case FETCH_TOKENKEY_RAZER_SUCCESS:
			return {
				...state,
				tokenKeyRazer: action.payload,
				loading: false,
				error: null,
			};

			case UPDATE_TOKENKEY_GHL_SUCCESS:
				return {
					...state,
					tokenConfigKeyGhl: action.payload,
					loading: false,
					error: null,
				};
	
			case FETCH_TOKENKEY_GHL_SUCCESS:
				return {
					...state,
					tokenKeyGhl: action.payload,
					loading: false,
					error: null,
				};

		case STAFF_LOGIN_SUCCESS:
			// console.log('action.payload.', action.payload);
			if (action.payload.role === 'staff') {
				localStorage.setItem('laundro-v2-staff-token', action.payload.token);
			}

			return {
				...state,
				[action.payload.role]: action.payload,
				[`${action.payload.role}Token`]: action.payload.token,
				loading: false,
				error: null,
			};

		// user login and registration success
		case OPERATOR_LOGIN_SUCCESS:
			// console.log('action.payload.', action.payload);
			if (action.payload.role === 'operator') {
				localStorage.setItem('laundro-v2-operator-token', action.payload.token);
			}

			return {
				...state,
				[action.payload.role]: action.payload,
				[`${action.payload.role}Token`]: action.payload.token,
				loading: false,
				error: null,
			};
		case DISTRIBUTOR_LOGIN_SUCCESS:
			if (action.payload.role === 'distributor') {
				localStorage.setItem('laundro-v2-distributor-token', action.payload.token);
			}

			return {
				...state,
				[action.payload.role]: action.payload,
				[`${action.payload.role}Token`]: action.payload.token,
				loading: false,
				error: null,
			};

		case USER_REGISTER_SUCCESS:
		case USER_DETAILS_SUCCESS:
			// console.log('action.payload', action.payload);
			return {
				...state,
				[action.payload.role]: action.payload,
				loading: false,
				error: null,
			};

		case CHANGE_PASSWORD_SUCCESS:
			// console.log("action.payload", action.payload);
			return {
				...state,
				password: action.payload,
				loading: false,
				error: null,
			};

		case LOYALTY_ADMIN_CHECK_SUCCESS:
			return {
				...state,
				loyaltyToken: action.payload.data,
				error: null,
			};

		case FETCH_OPERATORS_SUCCESS:
			// console.log('action.payload', action.payload);
			return {
				...state,
				operators: action.payload.data,
				loading: false,
				error: null,
			};

		case DISTRIBUTOR_VERIFY_SUCCESS:
			return {
				...state,
				authenticated: action.payload,
				loading: false,
				error: null,
			};

		case OPERATOR_VERIFY_SUCCESS:
			return {
				...state,
				authenticated: action.payload,
				loading: false,
				error: null,
			};

		case STAFF_VERIFY_SUCCESS:
			return {
				...state,
				authenticated: action.payload,
				loading: false,
				error: null,
			};

		case FETCH_2FA_SUCCESS:
			return {
				...state,
				mfa: action.payload,
				loading: false,
				error: null,
			};

		case USER_2FA_VERIFY_SUCCESS:
			return {
				...state,
				authenticated: action.payload,
				loading: false,
				error: null,
			};

		case USER_VERIFY_SUCCESS:
			return {
				...state,
				authenticated2: action.payload,
				loading: false,
				error: null,
			}

		case LOGOUT_SUCCESS:
			return initialState;

		// user auth error and logout
		case USER_REGISTER_FAIL:
		case USER_VERIFY_FAIL:
		case OPERATOR_LOGIN_FAIL:
		case CHANGE_PASSWORD_FAIL:
		case CREATE_RM_FAIL:
		case CREATE_RAZER_FAIL:
		case USER_DETAILS_FAIL:
		case FETCH_RM_FAIL:
		case FETCH_TOKENKEY_FAIL:
		case UPDATE_TOKENKEY_FAIL:
		case CREATE_DUITNOW_FAIL:
		case FETCH_DUITNOW_FAIL:
		case FETCH_TOKENKEY_DUITNOW_FAIL:
		case UPDATE_TOKENKEY_DUITNOW_FAIL:
		case FETCH_TOKENKEY_RAZER_FAIL:
		case UPDATE_TOKENKEY_RAZER_FAIL:
		case DISTRIBUTOR_LOGIN_FAIL:
		case FETCH_OPERATORS_FAIL:
		case LOYALTY_ADMIN_CHECK_FAIL:
		case STAFF_LOGIN_FAIL:
		case DISTRIBUTOR_VERIFY_FAIL:
		case OPERATOR_VERIFY_FAIL:
		case STAFF_VERIFY_FAIL:
		case FETCH_2FA_FAIL:
		case USER_2FA_VERIFY_FAIL:
		case CREATE_GHL_FAIL:
		case FETCH_TOKENKEY_GHL_FAIL:
		case UPDATE_TOKENKEY_GHL_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		default:
			return state;
	}
};

export const dashboardFilter = () => async (dispatch) => {
	try {
		dispatch({ type: SUMMARY_FILTER_SUCCESS });
	} catch (error) {
		dispatch({ type: SUMMARY_FILTER_FAIL, payload: returnError(error) });
	}
};

export const salesDashboardFilter = () => async (dispatch) => {
	try {
		dispatch({ type: SALES_PERFORMANCE_FILTER_SUCCESS });
	} catch (error) {
		dispatch({ type: SUMMARY_FILTER_FAIL, payload: returnError(error) });
	}
};

export const machineUtilizationFilter = () => async (dispatch) => {
	try {
		dispatch({ type: MACHINE_UTIL_FILTER_SUCCESS });
	} catch (error) {
		dispatch({ type: SUMMARY_FILTER_FAIL, payload: returnError(error) });
	}
};

export const clearFilter = () => async (dispatch) => {
	try {
		dispatch({ type: CLEAR_FILTER_SUCCESS });
		dispatch({ type: 'CLEAR_COIN_REPORT' });
	} catch (error) {
		dispatch({ type: SUMMARY_FILTER_FAIL, payload: returnError(error) });
	}
};

const filterInitialState = {
	summaryDashboardFilter: false,
	salesPerformanceFilter: false,
	machineUtilFilter: false,
};

export const filterReducer = function (state = filterInitialState, action) {
	switch (action.type) {
		// this is for fetching loading time setter
		case SUMMARY_FILTER_SUCCESS:
			return {
				summaryDashboardFilter: true,
				salesPerformanceFilter: false,
				machineUtilFilter: false,
			};

		case SALES_PERFORMANCE_FILTER_SUCCESS:
			return {
				summaryDashboardFilter: false,
				salesPerformanceFilter: true,
				machineUtilFilter: false,
			};
		case MACHINE_UTIL_FILTER_SUCCESS:
			return {
				summaryDashboardFilter: false,
				salesPerformanceFilter: false,
				machineUtilFilter: true,
			};
		case CLEAR_FILTER_SUCCESS:
		case SUMMARY_FILTER_FAIL:
		case SALES_PERFORMANCE_FILTER_FAIL:
		case MACHINE_UTIL_FILTER_FAIL:
			return {
				summaryDashboardFilter: false,
				salesPerformanceFilter: false,
				machineUtilFilter: false,
			};

		default:
			return state;
	}
};
